import React from "react"
import { graphql, navigate } from "gatsby"

import Layout from "../components/layout"
import DeathNav from "../components/table-navs/nav-death"
import Seo from "../components/seo"
let redirected = false

export default function DeathPage({data,path}){
  let paths = data.allMarkdownRemark.edges.map(e => e.node.fields.path)
  console.log(paths)
  if(typeof window !== "undefined" && !redirected){
    // navigate("/"+paths[Math.floor(Math.random()*paths.length)])
    // redirected = true
  }
  return (
    <DeathNav startShowing={true} />
  )
}



export const query = graphql`
query GetDeathPages {
  allMarkdownRemark(filter: {fields: {table: {eq: "death"}}}) {
    edges {
      node {
        id
        fields {
          path
          slug
        }
      }
    }
  }
}
`
