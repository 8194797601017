import React, {useEffect, useState, useMemo} from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import randomPage from "../randompage.js";
const linkPositions = [6,9,15,18,26,34,35,48,55,56,89,102,104,106,108,109,127,132,138,140,149,164,166,176,180,185,190,191,196,203,217,222,232,235,238,244,259,264,282,288,305,323,328,340,348,362,363,365,391,397]
const linkPositionsMobile = [1,12,31,37,39,40,47,52,58,64,72,77,78]

export default function DeathNav ({frontmatter,fields,children, startShowing}){
  let [hasLocalStorage,setHasLocalStorage] = useState(true);
  let [bombs,setBombs] = useState(linkPositions);
  let backgroundImages = frontmatter && frontmatter.background_images ? frontmatter.background_images.map(e=>e.childImageSharp) : null;
  let images = []
  if(backgroundImages){
    images = images.concat(backgroundImages)
  }
  let [bg,setBg] = useState({})
  // setBg(images[0])

  useEffect((e) => {
    if(images.length){
      setBg(images[Math.floor(Math.random()*images.length)])
    }

    if(typeof window !== "undefined"){
      if(!window.hasOwnProperty("localStorage")){
        setHasLocalStorage(false)
      }
      if(window.innerWidth < 768){
        setBombs(linkPositionsMobile);
      }
      window.addEventListener("resize",()=> {
        if(window.innerWidth < 768){
          setBombs(linkPositionsMobile);
        } else {
          setBombs(linkPositions);
        }
      }, {passive: true})
    }
  },[])
  const [navOpen,setNavOpen] = useState(startShowing ? true : false)
  const handleRecall = (e) => setNavOpen(!navOpen)

  return <StaticQuery
      query={graphql`
        query DeathNavQuery {
          menu: file(relativePath: { eq: "death-menu.png" }) {
            publicURL
          }
          tile: file(relativePath: { eq: "background_tile.png" }) {
            publicURL
          }
          recall: file(relativePath: { eq: "death_menu_recall_button.png" }) {
            publicURL
          }
          hover: file(relativePath: { eq: "rollover.png" }) {
            publicURL
          }
          bomb: file(relativePath: { eq: "death-menu-bomb.png" }) {
            publicURL
          }
          plain: file(relativePath: { eq: "death-menu-plain.png" }) {
            publicURL
          }
          deathpages: allMarkdownRemark(filter: {fields: {table: {eq: "death"}}}) {
            edges {
              node {
                fields {
                  slug
                  path
                }
              }
            }
          }
          allMarkdownRemark {
            group(field: fields___table) {
              distinct(field: fields___table)
              edges {
                node {
                  fields {
                  	slug
                  }
                }
              }
            }
          }
        }
      `}
      render={ data => {
        const genThumbs = (pages,data) => {
          let pagesUsed = 0;
          let navThumbs = [];
          for (var i = 0; i < 400; i++) {
            navThumbs.push(bombs.indexOf(i) > -1 ?
            <a key={i} className="death-menu-tile death-menu-tile-link" onClick={ e => randomPage(e,"death",data.allMarkdownRemark)} href={hasLocalStorage ? "" : "/"+pages[++pagesUsed%pages.length]}></a> :
              <div key={i} className="death-menu-tile"></div>)
          }
          return navThumbs;
        }
        console.log("the fields",fields,data.deathpages.edges)
        return (<div id="death-nav">
          <div id="death-recall" onClick={handleRecall} style={{
            backgroundImage: `url("${data.recall.publicURL}")`,
          }}></div>
          <div id="death-menu" className={navOpen ? "death-nav-open" : ""} style={{
            backgroundImage: `url("${data.menu.publicURL}")`,
          }}><div id="death-menu-tiles">{genThumbs(data.deathpages.edges.filter(e => fields ? e.node.fields.slug !== fields.slug : true).map(e => e.node.fields.path),data)}</div>
          </div>
          <div className="page-bg">
            {bg.gatsbyImageData && <GatsbyImage object-fit="cover" image={getImage(bg.gatsbyImageData)} alt="" />}
          </div>
          <div className={"page-wrapper page-"+(fields && fields.slug ? fields.slug.slice(1,-1): "")}>
          {children}
        </div>
      </div>)
      }
    }
  />
}
